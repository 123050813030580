<template>
    <modal ref="modalCategoriasComprar" titulo="Categorías a comprar" tamano="modal-lg" no-aceptar no-cancelar>
        <div class="row mx-0 justify-center">
            <div v-for="(data,index) in categorias" :key="index" class="col-8 mb-4">
                <div class="row mx-0 mb-1">
                    <p class="col-auto px-0 f-16 text-general">
                        {{ data.categoria }}
                    </p>
                    <p class="col-auto px-0 text-gris2 ml-auto">
                        {{ separadorNumeroDecimales(data.total_compra) }}
                    </p>
                </div>
                <div class="row mx-0 br-20 bg-fondo" style="height:10px;">
                    <div class="h-100 br-20" :style="`background-color:#DFE4E8;width:${data.porcentaje}%;`" />
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    data(){
        return {
            categorias: [],
        }
    },
    methods: {
        toggle(categorias){
            let valorMayor = categorias[0].total_compra;
            categorias.map(el =>{
                if(el.total_compra != valorMayor){
                    el.porcentaje = el.total_compra / valorMayor * 100;
                } else {
                    el.porcentaje = 100;
                }
            });
            this.categorias = categorias;
            this.$refs.modalCategoriasComprar.toggle();
        }
    }
}
</script>